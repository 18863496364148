// @ts-nocheck
type BuffedEmitterProps<T> = {
    emitInterval?: number;
    maxItems?: number;
    action: (buffer: T[]) => Promise<void> | void;
    errorHandler?: (error: Error) => void | Promise<void>;
};

class BufferedEmitter<T> {
    private buffer: T[];
    emitInterval: number;
    maxItems: number;
    private intervalTimeoutId: NodeJS.Timeout;
    private action: (buffer: T[]) => Promise<void> | void;
    private errorHandler?: (error: Error) => void | Promise<void>;

    constructor({
        emitInterval,
        maxItems,
        errorHandler,
        action,
    }: BuffedEmitterProps<T>) {
        this.buffer = [];
        this.emitInterval = emitInterval || 300;
        this.maxItems = maxItems || 10;
        this.action = action;
        this.errorHandler = errorHandler;
        this.flush(this.buffer);
    }

    add(data: T): void {
        if (data && data['ExtraData'] && !!data['ExtraData']['skipBuffer']) {
            this.buffer.push(data);
            this.cleanup();
        }
        this.buffer.push(data);

        if (this.buffer.length >= this.maxItems) {
            const items = this.buffer.splice(0, this.maxItems);
            this.flush(items);
        }
    }

    private async flush(items?): Promise<void> {
        this.intervalTimeoutId && clearTimeout(this.intervalTimeoutId);
        this.intervalTimeoutId = setTimeout(() => {
            this.flush(this.buffer.splice(0, this.maxItems));
        }, this.emitInterval);

        if (!items.length) return;

        try {
            await this.action(items);
        } catch (e) {
            if (this.errorHandler) {
                this.errorHandler(e);
            }
        }
    }

    async cleanup(): Promise<void> {
        const items = this.buffer.splice(0, this.maxItems);
        await this.flush(items);
    }
}

export default BufferedEmitter;
