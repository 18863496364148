// @ts-nocheck
import { defaultFilterKeys, filterObjectKeys } from '../utils';

import { createGoogleTagManager as myAsurionCreateGoogleTagManager } from '@soluto-private/myasurion-analytics';

// These keys are allowed for GTM only; these are used for enhanced conversion tracking
// and are not exposed in plaintext within GTM.
const FORCE_INCLUDE_KEYS = ['mdn', 'email', 'phone'];

function customTrackMethod({ payload, options, config }) {
    if (typeof window.dataLayer !== 'undefined') {
        const { anonymousId, userId, properties } = payload;
        let formattedPayload = { ...properties };

        const eventKeysToFilter = defaultFilterKeys.filter(
            (key) => !FORCE_INCLUDE_KEYS.includes(key.toLowerCase())
        );
        formattedPayload = filterObjectKeys(
            formattedPayload,
            eventKeysToFilter
        );

        formattedPayload['DispatchedBy'] = 'MAP-GTMPlugin';

        if (userId) {
            formattedPayload.userId = userId;
        }
        if (anonymousId) {
            formattedPayload.anonymousId = anonymousId;
        }
        if (!properties.category) {
            formattedPayload.category = 'All';
        }
        if (config.debug) {
            console.log('gtag push', {
                event: payload.event,
                ...formattedPayload,
            });
        }
        window.dataLayer.push({
            event: payload.event,
            ...formattedPayload,
        });
    }
}

const googleTagMangerPluginOverrides = {
    track: customTrackMethod,
};

/**
 * @param {string}  containerId     Uniquely identifies the GTM Container.
 * @param {string}  preview         Preview mode environment.
 * @param {string}  auth            Preview mode authentication credentials.
 */
const createGoogleTagManager = (
    containerId = process.env.GOOGLE_CONTAINER_ID,
    preview?: string,
    auth?: string
) =>
    myAsurionCreateGoogleTagManager(
        containerId,
        preview,
        auth,
        googleTagMangerPluginOverrides
    );

export default createGoogleTagManager;
