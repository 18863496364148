// @ts-nocheck
import {
    AnalyticsEventModel,
    AnalyticsPlugin,
} from '@soluto-private/myasurion-analytics';
import pRetry, { Options as PRetryOptions } from 'p-retry';

import BufferedEmitter from './BufferedEmitter';
import lifecycle from 'page-lifecycle';

const retryConfig: PRetryOptions = {
    retries: 3,
    maxTimeout: 2000,
};

type TrackEvent = {
    payload: AnalyticsEventModel;
};

const requestIdleCallback = window?.['requestIdleCallback'] || setTimeout;

export async function postToEnterprisePipeline(
    url: URL,
    apiKey: string,
    data: AnalyticsEventModel[]
) {
    await fetch(url.href, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
        },
        body: JSON.stringify(data),
    });
}

const solutoEnterprise = (): AnalyticsPlugin => {
    let bufferedEmitter: BufferedEmitter<AnalyticsEventModel>;

    async function sendAnalytics(data: AnalyticsEventModel[]) {
        const url = new URL(
            '/stream?eventType=analytics',
            process.env.ENTERPRISE_LISTENER_URL
        );

        await requestIdleCallback(async () => {
            await pRetry(
                async () =>
                    await postToEnterprisePipeline(
                        url,
                        process.env.ENTERPRISE_LISTENER_KEY,
                        data
                    ),
                retryConfig
            );
        });
    }

    return {
        name: 'soluto-enterprise',
        config: {},

        initialize: () => {
            bufferedEmitter = new BufferedEmitter<AnalyticsEventModel>({
                maxItems: 10,
                emitInterval: 2000,
                action: sendAnalytics,
            });

            lifecycle.addEventListener('statechange', function (event) {
                if (
                    event.newState === 'hidden' ||
                    event.newState === 'passive'
                ) {
                    bufferedEmitter.cleanup();
                }
            });
        },
        track: ({ payload }) => {
            const originalEvent = JSON.parse(
                JSON.stringify(payload.properties.originalEventModel)
            );
            originalEvent['MetaData']['DispatchedBy'] =
                'MAP-SolutoEnterprisePlugin';
            if (!!payload.properties.skipBuffer) {
                bufferedEmitter.maxItems = 1;
            }
            bufferedEmitter.add(originalEvent);
            if (!!payload.properties.immediateDispatch) {
                bufferedEmitter.cleanup();
            }
        },
        loaded: () => {
            return true;
        },
    };
};

export default solutoEnterprise;
