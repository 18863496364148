export {
    createDispatcher,
    getDispatcher,
    MxAnalyticsDispatcher,
} from './analytics';
export type { AnalyticsPlugin, AnalyticsScope } from './analytics';
export {
    createGoogleTagManager,
    createMixpanelPlugin,
} from './analytics/helpers';
export { getIdentity, updateIdentity } from './analytics/identities';

export * from './fullstory';

import { monitor as _monitor } from '@soluto-private/mx-monitor';
/**
 * @deprecated Please use `monitor` from `@soluto-private/mx-monitor`
 */
export const monitor = _monitor;
