/**
 * MxAnalyticsDispatcher decorates AnalyticsDispatcher to add event typing.
 * Event type enables writer to categorize events and process them in respective manner.
 */
import {
    AnalyticsContext,
    AnalyticsExtender,
    DataMap,
    MxAnalyticsDispatcher as MyAsurionAnalyticsDispatcher,
    getUrlData,
} from '@soluto-private/myasurion-analytics';
import {
    getExperiment,
    getLanguage,
    getPartner,
    getPlans,
    getServices,
    getSession,
    getUserDeviceData,
} from './extra-data';

import { getIdentities } from './identities';

export class MxAnalyticsDispatcher<T> extends MyAsurionAnalyticsDispatcher<T> {
    dispatch(eventName = '', analyticsContext?: Partial<AnalyticsContext>): T {
        return super.dispatch(eventName, analyticsContext);
    }

    /**
     * @deprecated method is deprecated. Use "Identities" context property
     *      to identify user.
     */
    identify(id: string, identity?: unknown): T {
        return super.identify(id, identity);
    }

    /**
     * Wraps AnalyticsExtender into MxAnalyticsDispatcher, does not add any behavior to
     * extend functionality.
     */
    extend(
        ...extenders: Array<AnalyticsExtender<T>>
    ): MxAnalyticsDispatcher<T> {
        const { dispatch, context } = this._extend(...extenders);
        return new MxAnalyticsDispatcher<T>(dispatch.bind(this), context);
    }

    protected isTest(analyticsContext?: Partial<AnalyticsContext>): boolean {
        const isProduction = process.env.APP_ENV === 'production';
        const urlData = getUrlData() as { QpCid?: string } | undefined;
        const isCidTest = urlData?.['QpCid']?.toLowerCase() === 'test';
        return super.isTest(analyticsContext) || !!isCidTest || !isProduction;
    }

    protected getMetaData(
        analyticsContext?: Partial<AnalyticsContext>
    ): DataMap {
        return {
            ...super.getMetaData(analyticsContext),
            IgnoreScope: analyticsContext?.ExtraData?.ignoreScope || false,
        };
    }

    protected getExtraData(
        analyticsContext?: Partial<AnalyticsContext>
    ): DataMap {
        return {
            ...super.getExtraData(analyticsContext),
            partner: getPartner(),
            language: getLanguage(),
            plans: getPlans(),
            services: getServices(),
            session: getSession(),
            ...getUserDeviceData(),
            ...getExperiment(),
        };
    }

    protected getIdentities(
        analyticsContext?: Partial<AnalyticsContext>
    ): DataMap {
        return {
            ...analyticsContext?.Identities,
            ...getIdentities(),
        };
    }
}
