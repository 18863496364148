import {
    CORE_IDENTITIES,
    Identities,
    updateIdentity as myAsurionUpdateIdentity,
} from '@soluto-private/myasurion-analytics';
import {
    hasBeenInvokedByAnalytics,
    hasBeenInvokedByAnalyticsTests,
    hasBeenInvokedByRootApp,
} from '../utils';

/**
 * Updates the analytics identity with a given value.
 * This identity will be attached to all the analytics in the platform
 *
 * **NOTE** do not update the AsurionId, UserBrowserId and SessionId keys.
 * @param key The identity's key.
 * @param value The identity's value
 */
export const updateIdentity = (key: Identities, value: string | undefined) => {
    if (
        CORE_IDENTITIES.includes(key) &&
        !(
            hasBeenInvokedByRootApp() ||
            hasBeenInvokedByAnalytics() ||
            hasBeenInvokedByAnalyticsTests()
        )
    ) {
        throw new Error('Invalid action. You cannot set this identity.');
    }

    myAsurionUpdateIdentity(key, value);
};

export {
    getIdentity,
    getIdentities,
} from '@soluto-private/myasurion-analytics';
