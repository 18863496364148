import type { ExperimentData } from '@soluto-private/myasurion-analytics';

let currentId: string;
let currentVariant: number;

export const startExperimentListener = () => {
    const GTAG_EVENT = 'event';
    const OPTIMIZE_EVENT_NAME = 'optimize.callback';

    function gtag() {
        if (typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push(arguments);
        }
    }

    /* suppressing tsc as this event needs to be pushed 
       to dataLayer with Arguments object */
    /* @ts-ignore */
    gtag(GTAG_EVENT, OPTIMIZE_EVENT_NAME, {
        callback: (experimentVariant: number, experimentId: string) => {
            currentId = experimentId;
            currentVariant = experimentVariant;
        },
    });
};

export const getExperiment = (): ExperimentData => ({
    ExperimentId: currentId,
    ExperimentVariant: currentVariant,
});
