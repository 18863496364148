import { UserBrowser } from '@soluto-private/mx-user-browser';
import { updateIdentity } from './external';

export const hydrateBrowserIdentity = (): void => {
    try {
        updateIdentity('UserBrowserId', UserBrowser.Id);
    } catch (error) {
        console.warn('Cannot persist browser id identity', { error });
    }
};
