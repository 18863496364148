import { hasBeenInvoked } from '@soluto-private/myasurion-analytics';

const ROOT_APP_FILE_NAME = 'oneservice-root.js';
const ANALYTICS_UTILITY_FILE_NAME = 'mx-package-analytics.js';
const ANALYTICS_UTILITY_TESTS_FILE_NAME = 'index.spec.ts';

export const hasBeenInvokedByRootApp = () => hasBeenInvoked(ROOT_APP_FILE_NAME);
export const hasBeenInvokedByAnalytics = () =>
    hasBeenInvoked(ANALYTICS_UTILITY_FILE_NAME);
export const hasBeenInvokedByAnalyticsTests = () =>
    hasBeenInvoked(ANALYTICS_UTILITY_TESTS_FILE_NAME);
