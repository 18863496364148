// @ts-nocheck
import {
    RoutingEvent,
    createInteraction,
    createSession,
    getSession,
    handleSession as myAsurionHandleSession,
    setSession,
} from '@soluto-private/myasurion-analytics';

const refreshInteraction = (): void => {
    const session = getSession();
    if (session) {
        session.interaction = createInteraction();
        setSession(session);
    } else {
        setSession(createSession());
    }
};

let setupListeners = (): void => {
    //to ensure is called once
    setupListeners = () => {};
    window.addEventListener(
        'single-spa:before-routing-event',
        ({ detail }: CustomEvent<RoutingEvent>) => {
            const getBasePath = (path: string): string => {
                const formatted = path.startsWith('/') ? path : `/${path}`;
                return formatted.split('/')[1];
            };
            const oldUrl = new URL(detail.oldUrl);
            const newUrl = new URL(detail.newUrl);
            const newApp = getBasePath(newUrl.pathname);
            const oldApp = getBasePath(oldUrl.pathname);
            if (oldApp !== newApp) {
                refreshInteraction();
            }
        }
    );
    window.addEventListener('load', () => {
        refreshInteraction();
    });
};

export const handleSession = (): void => {
    myAsurionHandleSession();
    setupListeners();
};

export {
    getSession,
    refreshSession,
    setSession,
} from '@soluto-private/myasurion-analytics';
