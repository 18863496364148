// @ts-nocheck
import { defaultFilterKeys, filterObjectKeys } from '../utils';

import { AnalyticsPlugin } from '@soluto-private/myasurion-analytics';
import mixpanelPlugin from '@analytics/mixpanel';

declare global {
    interface Window {
        mixpanel: {
            track(
                event_name: string,
                properties?: Record<string, unknown>,
                options?: Record<string, unknown>,
                callback?: unknown
            ): void;
        };
    }
}

function renameDefaultProperties(modifiedPayload) {
    modifiedPayload['$os'] = modifiedPayload.OsType;
    modifiedPayload['$browser'] = modifiedPayload.BrowserType;
    modifiedPayload['$browser_version'] = modifiedPayload.BrowserVersion;
    modifiedPayload['$screen_width'] = modifiedPayload.ScreenWidth;
    modifiedPayload['$screen_height'] = modifiedPayload.ScreenHeight;

    delete modifiedPayload.OsType;
    delete modifiedPayload.BrowserType;
    delete modifiedPayload.BrowserVersion;
    delete modifiedPayload.ScreenWidth;
    delete modifiedPayload.ScreenHeight;
}

const mixpanelPluginOverrides = {
    track: ({ payload }) => {
        let modifiedPayload = { ...payload.properties };

        modifiedPayload['Scope'] = modifiedPayload.originalEventModel.Scope;

        modifiedPayload = filterObjectKeys(modifiedPayload, defaultFilterKeys);
        renameDefaultProperties(modifiedPayload);

        modifiedPayload['DispatchedBy'] = 'MAP-MixPanelPlugin';
        window.mixpanel.track(payload.event, modifiedPayload);
    },
};

/**
 * @param {string}  token      Token associated to a mixpanel project.
 */
const createMixpanelPlugin = (
    token = process.env.MIXPANEL_TOKEN
): AnalyticsPlugin => {
    const mixpanelPluginInstance = mixpanelPlugin({
        token,
    });
    return Object.assign({}, mixpanelPluginInstance, mixpanelPluginOverrides);
};

export default createMixpanelPlugin;
