// @ts-nocheck
import { UserAgentData } from '@soluto-private/myasurion-analytics';
import { UserBrowser } from '@soluto-private/mx-user-browser';

export const getUserDeviceData = (): UserAgentData => {
    if (!navigator?.userAgent) {
        return;
    }

    return {
        OsType: UserBrowser.UserAgent.Os,
        OsVersion: UserBrowser.UserAgent.OsVersion,
        BrowserType: UserBrowser.UserAgent.Browser,
        BrowserVersion: UserBrowser.UserAgent.BrowserVersion,
        AgentVendor: UserBrowser.UserAgent.DeviceVendor,
        AgentModel: UserBrowser.UserAgent.DeviceModel,
    };
};
