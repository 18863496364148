import { UserBrowser } from '@soluto-private/mx-user-browser';
import { getQueryParam } from '../utils';
import { updateIdentity } from './external';

const STORAGE_KEY = 'mx-user-browser-session-id';
const queryKey = 'QPMxSessionId';

const persistIdentity = (identity: string): void => {
    try {
        window.sessionStorage.setItem(STORAGE_KEY, identity);
        UserBrowser.SessionId = identity;
    } catch (error) {
        console.warn('Cannot persist session id identity', { error });
    }
};

export const hydrateSessionIdentity = (): void => {
    let sessionId = getQueryParam(queryKey);

    if (!sessionId) {
        sessionId = UserBrowser.SessionId;
    } else {
        persistIdentity(sessionId);
    }

    updateIdentity('SessionId', sessionId);
};
